<template>
    <div v-if="isVisible" id="float-icon" class="float-icon" :style="floatIconStyle">
      <button class="close-button" @click="closeFloatIcon">✖</button>
      <img src="/build/assets/img/icons/banners/promotion.png" alt="floating image" @click="$router.push('/promotion')"/>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FloatIcon',
    data() {
      return {
        position: '45%', // Start at 45% from the top to center the div
        direction: 'down',
        animationDuration: 8, // duration in seconds
        isVisible: true, // Control visibility of the float icon
      };
    },
    computed: {
      floatIconStyle() {
        return {
          position: 'fixed',
          right: '0px',
          top: this.position,
          transition: `top ${this.animationDuration}s ease-in-out`,
        };
      },
    },
    mounted() {
    if (this.direction === 'down') {
        this.position = '80%'; // Move down to 80%
        this.direction = 'up';
        } else {
        this.position = '45%'; // Move up to 45%
        this.direction = 'down';
        }
      this.animateFloatIcon();
    },
    methods: {
      animateFloatIcon() {
        setInterval(() => {
          if (this.direction === 'down') {
            this.position = '80%'; // Move down to 80%
            this.direction = 'up';
          } else {
            this.position = '45%'; // Move up to 45%
            this.direction = 'down';
          }
        }, this.animationDuration * 1000);
      },
      closeFloatIcon() {
        this.isVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .float-icon {
    width: 80px;
    height: 80px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .float-icon img {
    width: 65px;
    border-radius: 5px;
    height: 65px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .close-button {
    position: absolute;
    top: -7px;
    left: -15px;
    background: none;
    border: none;
    font-size: 19px;
    cursor: pointer;
  }
  </style>
  