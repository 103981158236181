<template>
    <div>
        <NavBar v-if="!$route.path.includes('rule')"/>
        <router-view :class="$route.path.includes('login') ? '' : 'middle-container'"></router-view>
        <Footer v-if="!$route.path.includes('rule') && $route.path !== '/'"/>
        <!-- <div class="social-contacts" id="social-contacts">
            <div
                style="position: relative"
                class="cart-container"
                v-if="
                    $route.path.includes('maung-fb') ||
                    $route.path.includes('body-fb')
                "
                @click="openModalVoucher()"
            >
                <div>
                    <i class="ni ni-cart mb-2"></i>
                </div>
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('2d')"
                    >{{ this.$store.state.twod_slip_data.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-if="$route.path.includes('fb')"
                    >{{ this.$store.state.fb_order.fb_matches.length }}</span
                >
                <span
                    class="
                        badge badge-md badge-circle badge-floating badge-danger
                        border-white
                        cart-number
                    "
                    v-else-if="$route.path.includes('3d')"
                    >{{ this.$store.state.threed_slip_data.length }}</span
                >
            </div>
        </div> -->


        <van-overlay :show="isLoading" @click="isLoading = false">
            <div class="wrapper col-12 d-flex h-100vh" @click.stop>
              <div class="mx-auto my-auto col-11">
                <img src="/build/assets/img/icons/banners/popup.gif" class="m-0" alt="" style="width: 100%; height: auto; border-radius: 5px;">
                <div class="mt-3 d-flex flex-column">
                  <!-- check box -->
                  <div class="mx-auto mb-3">
                    <div class="d-flex">
                      <input
                        type="checkbox"
                        value=""
                        class="mb-1 mr-2 p-0 m-0"
                      />
                      <h3 class="text-white text-center p-0 m-0">Hide For Today</h3>
                    </div>
                  </div>

                  <button class="btn btn-primary btn-block w-25 mx-auto text-uppercase" @click="isLoading = false">Close</button>
                </div>
              </div>
            </div>
        </van-overlay>

        <audio controls ref="audioPlayer" v-show="false">
          <!-- Replace 'your-audio-file.mp3' with the path to your audio file -->
          <source src="https://yoeyar.vip/build/assets/music/SaKaWarMyay.mp3" type="audio/mpeg">
          Your browser does not support the audio element
        </audio>
        <FloatIcon />
    </div>
</template>
<script>

import { Dialog } from 'vant';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    this.fetchWebsiteData()
  },
  computed: {
    ...mapGetters(['getFBOrder']),
    ...mapState(['music']),
  },

  watch: {
    music() {
      //do something on data change
      const audio = this.$refs.audioPlayer;
      if(this.music === true){
        this.$refs.audioPlayer.play();
              // Start audio playback
        audio.play();
      } else {
        audio.pause();
      }
    }
  },

  methods: {  
    ...mapMutations(['setWholeFBMatches']),
    ...mapActions(['fetchUser']),
    closeModalOutside(event) {
      if (event.target.classList.contains('modal')) {
        this.$router.go();
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        console.log('this.$store.state.androidVersion', this.$store.state.androidVersion);
        if(res.data.data[0].androidVersion !== this.$store.state.androidVersion) {
          $('#version').modal('show');
        }
      } catch (error) {
        console.log(error);
      }
    },
    openModalVoucher() {
      if (this.$store.state.fb_order.fb_matches.length === 0) {
        const dataError = this.$store.state.language === 'en'
          ? 'Please select some teams!'
          : 'အသင်းတစ်ချို့ ရွေးပေးပါ။';
        this.dialog(dataError);
      } else {
        const matches = this.getFBOrder.fb_matches;
        if (!this.$store.state.betAmount) {
          $('#amount').modal('show');
        } else {
          for (let i = 0; i < matches.length; i += 1) {
            matches[i].bet_amount = this.$store.state.betAmount;
          }
          this.setWholeFBMatches(matches);
          $('#mobile-voucher').modal('show');
        }
      }
    },

    dialog(text) {
      Dialog.alert({
        message: text,
        confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Go Back'
                      : 'နောက်သို့ပြန်သွားမည်။',
      }).then(() => {
        // on close
      });
    },
  },
};
</script>
<style scoped>
.floating {
    position: fixed;
    width: 90px;
    z-index: 1;
    animation: floating 20s infinite;
    bottom: 130px;
    right: 0%;
}
#closeButton {
    border: none;
    background: none;
    position: absolute;
    left: -15px;
    font-size: 20px;
    font-weight: bold;
    color: #b7b7b7;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}


.cart-number {
    top: -2px !important;
    left: -2px !important;
}
.cart-number {
    position: absolute;
    top: -3px;
    left: -3px;
    text-align: center;
}
.middle-container {
    min-height: 75vh;
}
@media (max-width: 768px) {
    .cart-container {
        font-size: 30px !important;
    }
    .social-contacts div {
        width: 60px !important;
        height: 60px !important;
        margin-top: 15px;
    }
    #social-contacts .badge-circle.badge-md {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
    .cart-container {
        visibility: visible;
        font-size: 16px;
    }
    .middle-container {
        min-height: 100vh;
        margin: 0px !important;
        /* padding-top: 0.1rem !important; */
        /* margin-bottom: 50px !important; */
    }
}
</style>
