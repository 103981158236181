<template>
  <div>
    <Sticky>
      <nav class="navbar navbar-horizontal navbar-expand-lg" style="background: #ffffff;">
        <div class="container-fluid">
          <div
            class="navbar-brand text-white"
            :class="
              $route.path !== '/home' ? 'd-flex justify-content-between' : ''
            "
          >
            <div
              class="d-flex flex-column banking-icons my-auto ml-1 mr-3"
              v-if="$route.path !== '/auth/login'"
            >
              <i
                class="fas fa-chevron-left mx-auto my-1 text-xl text-dark"
                @click="$router.go(-1)"
                v-if="$route.path !== '/home'"
              ></i>
            </div>
            <img
              src="/build/assets/img/brand/logo.png"
              alt=""
              @click="$router.push('/home')"
            />
            <!-- LOGO -->
          </div>
          <div class="d-flex">

            <!-- Refresh  -->
            <!-- <div
              class="navbar-brand text-white mx-3 d-flex flex-column"
              @click="$router.go()"
            >
              <i class="fas fa-redo mx-auto"></i>
              <div>
                {{ $store.state.language === 'en' ? 'Refresh' : 'Refresh' }}
              </div>
            </div> -->
            <div class="d-flex" v-if="$route.path !== '/auth/login'">
              <button class="login-btn my-auto" role="button" v-if="!isLogged" @click="$router.push('/auth/login')">
                Login
              </button>
              <button class="login-btn my-auto" role="button" @click="logout" v-else>
                Logout
              </button>
            </div>
            

            <!-- Language -->
            <div
              class="nav-link d-flex justify-content-between"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
                  <div id="navbar-language">
                    <img
                        :src="$store.state.language === 'en' ? 'https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/united-kingdom.png' : 'https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/myanmar.png'"
                        alt=""
                        class="mr-2"
                        style="width: 25px; height: 25px; border-radius: 50%;"
                      />
                  </div>
                </div>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbar-language"
                >
                  <a class="dropdown-item" @click="setLangLocalstorage('en')"
                    ><img
                      src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/united-kingdom.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />
                    {{
                      $store.state.language === 'en' ? 'English' : 'အဂ်လိပ်စာ'
                    }}</a
                  >
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="setLangLocalstorage('mm')"
                    ><img
                      src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/myanmar.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />{{
                      $store.state.language === 'en'
                        ? 'Myanmar (Burmese)'
                        : 'မြန်မာ (ဗမာစာ)'
                    }}</a
                  >
                </div>
          </div>
        </div>
      </nav>
    </Sticky>
  </div>
</template>
<script>
import { Toast, Dialog, Sticky } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Sticky },
  data() {
    return {
      // imageLink: `https://ui-avatars.com/api/?name=${this.$store.state.authUser.email}&background=0D8ABC&color=fff`,
      isLogged: false,
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          if(this.$route.path === '/home') {
            this.$router.go();
          } else {
            this.$router.push('/home');
            this.$router.go();
          }
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    closeNavCollapse() {
      $('#navbar-default').collapse('hide');
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      $('#navbar-default').collapse('hide');
    },
    redirectLink(link) {
      this.$router.push(`/${link}`).catch(() => {});
      $('#navbar-default').collapse('hide');
    },
    redirectRoute(path) {
      if (path === '2d') {
        this.$router.push('/user/2d');
      } else {
        this.$router.push('/user/3d');
      }
      return true;
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (authUser) {
      this.isLogged = true;
    }
  }
};
</script>
<style scoped>
/* CSS */
.login-btn {
  background-image: linear-gradient(#8635A5, #8635A5);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  /* margin: 5px; */
  padding: 5px 20px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.navbar {
  padding: 3px;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* border-bottom: 2px solid black; */
}
.navbar-horizontal .navbar-brand img {
  height: 40px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand.brand-text {
  font-size: 20px;
  color: red;
  margin-left: 0px;
  margin-right: 10px;
}
.navbar-brand {
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.bg-default {
  background-color: #02133E !important;
}
@media (max-width: 767.98px) {
  .navbar-brand {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
