<template>
  <div style="background: #ffffff !important; padding-bottom: 5rem;">
    <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
    <!-- <div class="col-12 d-flex my-0">
      <img
        class="mx-auto"
        src="https://cdn.gold549.com/build/assets/img/brand/logo.png"
        alt=""
        style="width: 120px; height: 120px; margin-top: -22px"
      />
    </div> -->
    
    <div class="col-lg-12 col-12 mx-auto px-2 d-flex main-image-wrapper">
            <b-carousel
                id="carousel-1"
                :interval="3000"
                controls
                fade
                style="width: 100%; height: 100%;"
                v-if="!threedData.embedded_link && !threedData.isLive"
            >
                <b-carousel-slide
                    img-src="/build/assets/img/icons/home-page-banners/1.png"
                ></b-carousel-slide>
                <b-carousel-slide
                    img-src="/build/assets/img/icons/home-page-banners/2.png"
                ></b-carousel-slide>
            </b-carousel>
        </div>

    <div style="padding-left: 10px; padding-right: 10px; border-radius: 15px; margin-left: 3px; margin-right: 3px; margin-top: 1rem; background: linear-gradient(#8635A5, #8635A5);">
      <NoticeBar
        class="col-lg-12 col-12 mx-auto notice-bar"
        scrollable
        :text="websiteInfo[0]?.noticeText"
        v-if="!threedData.embedded_link && !threedData.isLive"
      />
    </div>

    <div class="account-container-wrapper d-flex py-1 my-2 col-12">
      <div class="col-6 my-auto d-flex">
        <!-- <img src="https://cdn.gold549.com/build/assets/img/theme/profile.png" style="width: 92px; height: 92px" alt=""> -->
        <div class="w-50 text-center mx-4 pt-1" @click="$router.push('/deposit')">
          <img src="/build/assets/img/icons/Deposite.png" style="width: 25px; height: 25px" alt="">
          <span class="mx-auto mt-1 text-sm text-nowrap text-dark">
            <b>
              {{
                  $store.state.language === "en"
                      ? "Deposit"
                      : "ငွေသွင်း။"
              }}
            </b>
          </span>
        </div>
        <div class="w-50 text-center mx-4 pt-1" @click="$router.push('/withdraw')">
          <img src="/build/assets/img/icons/Withdraw.png" style="width: 25px; height: 25px" alt="">
          <span class="mx-auto mt-1 text-sm text-nowrap">
            <b>
              {{
                  $store.state.language === "en"
                      ? "Withdraw"
                      : "ငွေထုတ်။"
              }}
            </b>
          </span>
        </div>
      </div>

      <div class="col-6 p-0">
        <div class="pt-2" v-if="isLogged">
          <div class="d-flex text-wrap">
            <span class="mb-1 mx-2"> 
              <b>
                {{
                    $store.state.language === "en"
                        ? "Username"
                        : "အမည်"
                }}
              : </b></span>
              <span class="mb-1"> {{$store.state.authUser.name}} </span>
          </div>
          <div class="d-flex text-wrap">
            <span class="mb-1 mx-2">
              <b>
                {{
                    $store.state.language === "en"
                        ? "Balance"
                        : "ကျန်ငွေ"
                }}
              : </b></span>
            <span class="mb-1">
              <b>{{ Number($store.state.amount).toLocaleString() }}</b>
            {{ $store.state.language === 'en' ? 'MMK' : 'ကျပ်' }} </span>
          </div>
        </div>
        <div class="d-flex flex-column" v-if="!isLogged">
          <button class="detail-button w-auto text-nowrap mx-auto my-1 col-6" @click="$router.push('/auth/login')">Log In</button>
          <span class="text-wrap text-center">
            {{ $store.state.language === 'en' ? "Hello, Let's Login Now" : 'အခုပဲ log in ဝင်လိုက်ရအောင်။' }}
          </span>
        </div>
      </div>
    </div>

    <div class="justify-content-center cards-container-mobile">
      <!-- <div
        class="col-xl-6 col-6 home-cards pr-1"
        @click="redirectRoute('body-fb')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/body.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{ $store.state.language === 'en' ? 'Body' : 'ဘော်ဒီ' }}
            </h4>
          </div>
        </div>
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1"
        @click="redirectRoute('maung-fb')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/maung.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{ $store.state.language === 'en' ? 'Maung' : 'မောင်း' }}
            </h4>
          </div>
        </div>
      </div>

      <div
        class="col-xl-6 col-6 home-cards pr-1"
        @click="redirectRoute('betslip-history')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/old_vouchers.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{
                $store.state.language === 'en'
                  ? 'Voucher List'
                  : 'လောင်းထားသောပွဲများ'
              }}
            </h4>
          </div>
        </div>
      </div>
      <div
        class="col-xl-6 col-6 home-cards pl-1"
        @click="redirectRoute('history')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/transaction.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{
                $store.state.language === 'en'
                  ? 'Transactions'
                  : 'ငွေစာရင်းများ'
              }}
            </h4>
          </div>
        </div>
      </div>


      <div
        class="col-xl-6 col-6 home-cards pr-1"
        @click="redirectRoute('deposit')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/deposit.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{
                $store.state.language === 'en'
                  ? 'Deposit'
                  : 'ငွေသွင်းရန်'
              }}
            </h4>
          </div>
        </div>
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1"
        @click="redirectRoute('withdraw')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/withdraw.png"
              alt=""
              style="width: auto; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{
                $store.state.language === 'en'
                  ? 'Withdraw'
                  : 'ငွေထုတ်ရန်'
              }}
            </h4>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-6 home-cards pr-1" @click="redirectRoute('setting')">
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/setting.png"
              alt=""
              style="width: 30px; height: 30px"
            />
          </div>
          <div>
            <h4>
              {{ $store.state.language === 'en' ? 'Setting' : 'ကိုယ်ရေးအချက်အလက်' }}
            </h4>
          </div>
        </div>
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-1"
        @click="redirectRoute('Live')"
      >
        <div
          class="card d-flex justify-content-center align-items-center mb-2 pt-2"
          style="width: 100%; height: 65px; border-radius: 5px"
        >
          <div>
            <img
              src="https://cdn.gold549.com/build/assets/img/theme/live.png"
              alt=""
              style="width: 30px; height: 30px"
            />
          </div>
          <div>
            <h4>
              Live
            </h4>
          </div>
        </div>
      </div> -->
      <div class="container-fluid mt-1 px-0 d-flex">
        <!-- <div class="col-12"> -->
          
						<div class="col-3 pr-0 mx-0 pl-2 index_left">
							<ul class="nav nav-pills game-nav">
								<li class="nav-item">
									<a class="nav-link active" data-toggle="pill" href="#hotgames">
										<img src="/build/assets/img/icons/Popular Games.png" alt="hot games">
										<div>
                      <b>
                        {{ $store.state.language === 'en' ? 'Popular Games' : 'ရေပန်စားသည့်ဂိမ်းများ' }}
                      </b>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#football">
										<img src="/build/assets/img/icons/Football.png" alt="football">
										<div>
                      <b>
                        {{ $store.state.language === 'en' ? 'Football' : 'ဘောလုံး' }}
                      </b>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#casino">
										<img src="/build/assets/img/icons/Live Casino.png" alt="casino">
										<div>
                      <b>
                        {{ $store.state.language === 'en' ? 'Live Casino' : 'လိုက်ဖ်ကာစီနို' }}
                      </b>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#slot">
										<img src="/build/assets/img/icons/Slot.png" alt="slots">
										<div>
                      <b>
                        {{ $store.state.language === 'en' ? 'Slot' : 'စလော့' }}
                      </b>
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#fishing">
										<img src="/build/assets/img/icons/Fishing.png" alt="fishing">
										<div>
                      <b> 
                        {{ $store.state.language === 'en' ? 'Fishing' : 'ငါးပစ်' }}
                      </b>
										</div>
									</a>
								</li>
                <li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#lotto2">
										<img src="/build/assets/img/icons/2D.png" alt="2d">
										<div>
                      <b>
                        {{ $store.state.language === 'en' ? '2D' : 'နှစ်လုံး' }}
                      </b>
										</div>
									</a>
								</li>
                <li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#lotto3">
										<img src="/build/assets/img/icons/3D.png" alt="3d">
										<div>
                      <b> 
                        {{ $store.state.language === 'en' ? '3D' : 'သုံးလုံး' }}
                      </b>
										</div>
									</a>
								</li>
								<!-- <li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#esports">
										<img src="https://aubet77mmk.com/mobile/images/index/icon-esports.png" alt="esports">
										<div>
											E-sport
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#poker">
										<img src="https://aubet77mmk.com/mobile/images/index/icon-poker.png" alt="poker">
										<div>
											ပိုကာ
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" data-toggle="pill" href="#cock">
										<img src="https://aubet77mmk.com/mobile/images/index/icon-cock.png" alt="cock">
										<div>
											Cock
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" href="reward">
										<img src="https://aubet77mmk.com/mobile/images/index/icon-reward.png" alt="reward">
										<div>
											ဆုလာဘ်
										</div>
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" href="tournament">
										<img src="https://aubet77mmk.com/mobile/images/index/icon-tournament.png" alt="tournament">
										<div>
											ပြိုင်ပွဲ
										</div>
									</a>
								</li> -->
								
							</ul>
						</div>
						<div class="col-9 pl-0 pr-2 index_rightbar  mx-0">
							<div class="tab-content game">
								<div class="tab-pane" id="newgame">
									<div class="gwrap_1" id="newGameListID">
										
									</div>
								</div>
								<div class="tab-pane active" id="hotgames">
									<div class="gwrap_1">
										<!-- <a><img src="/build/assets/img/icons/cards/PP.png" alt="saba sport"></a>
                    <a><img src="/build/assets/img/icons/cards/PP.png" alt="saba sport"></a> -->
                    <a><img src="/build/assets/img/icons/slot/PP.png" alt="saba sport"></a>
                    <a><img src="/build/assets/img/icons/slot/PG.png" alt="saba sport"></a>
                    <a><img src="/build/assets/img/icons/slot/JDB.png" alt="saba sport"></a>
                    <a><img src="/build/assets/img/icons/slot/FaChai.png" alt="saba sport"></a>
										<!-- <a onclick="popUpGameLink('M8','SB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/sports-m8bet.png" alt="m8bet"></a>
										<a onclick="popUpGameLink('UG','SB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/sports-ug.png" alt="ug"></a>
										<a onclick="popUpGameLink('S3','SB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/sports-sbobet.png" alt="sbobet"></a> -->
									</div>
								</div>
								<div class="tab-pane fade" id="casino">
									<div class="gwrap_1">
										<a ><img src="/build/assets/img/icons/live-casino/PP.png" alt="allbet"></a>
										<!-- <a onclick="popUpGameLink('AG','LC','1','0',1000,600)"><img src="/build/assets/img/icons/cards/PP.png" alt="asia gaming"></a> -->
										<!-- <a onclick="popUpGameLink('BI','LC','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-biggaming.png" alt="biggaming"></a>
										<a onclick="popUpGameLink('MP','LC','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-mircogaming.png" alt="mircogaming"></a>
										<a onclick="popUpGameLink('PR','LC','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-pragmatic.png" alt="pragmatic play"></a>
										<a onclick="popUpGameLink('SA','LC','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-sagaming.png" alt="sa gaming"></a>
										<a onclick="popUpGameLink('S6','LC','1','S6_AWS',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-sexy.png" alt="sexy baccarat"></a>
										<a onclick="popUpGameLink('WC','LC','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/casino-wmcasino.png" alt="wm casino"></a> -->
										
									</div>
								</div>
								<div class="tab-pane fade" id="slot">
									<div class="gwrap_1">
										<a @click.prevent="redirectRoute('slots', {provider: 'Pragmatic'})"><img src="/build/assets/img/icons/slot/PP.png" alt="pragmatic play"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'Jili'})"><img src="/build/assets/img/icons/slot/JILI.png" alt="jili"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'Joker'})"><img src="/build/assets/img/icons/slot/Joker.png" alt="joker"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'Live22'})"><img src="/build/assets/img/icons/slot/Live22.png" alt="live22 slot"></a>
										<a @click.prevent="redirectRoute('slots', {provider: 'Spade'})"><img src="/build/assets/img/icons/slot/Spadegaming.png" alt="spade gaming"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'PGSoft'})"><img src="/build/assets/img/icons/slot/PG.png" alt="pg soft"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'JDB'})"><img src="/build/assets/img/icons/slot/JDB.png" alt="jdb slot"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'Fastspin'})"><img src="/build/assets/img/icons/slot/Fastspin.png" alt="fastspin"></a>
                    <a @click.prevent="redirectRoute('slots', {provider: 'FaChai'})"><img src="/build/assets/img/icons/slot/FaChai.png" alt="FaChai"></a>
									</div>
								</div>
								<!-- <div class="tab-pane fade" id="fish">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('CQ','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-cq9.png" alt="cq9"></a>
										<a onclick="popUpGameLink('DS','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-dragoonsoft.png" alt="dragoonsoft"></a>
										<a onclick="popUpGameLink('FK','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-funkygames.png" alt="funkygames"></a>
										<a onclick="popUpGameLink('JJ','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-jili.png" alt="jili"></a>
										<a onclick="popUpGameLink('JK','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-joker.png" alt="joker"></a>
										<a onclick="popUpGameLink('PS','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-playstar.png" alt="playstar"></a>
										<a onclick="popUpGameLink('SY','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-simpleplay.png" alt="simpleplay"></a>
										<a onclick="popUpGameLink('SG','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-spadegaming.png" alt="spadegaming"></a>
										<a onclick="popUpGameLink('YL','FH','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/fish-ylgaming.png" alt="ylgaming"></a>
									</div>
								</div>
								<div class="tab-pane fade" id="esports">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('IA','ES','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/esports-iasports.png" alt="iasports"></a>
										<a onclick="popUpGameLink('TF','SB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/esports-tfg.png" alt="tf gaming"></a>
									</div>
								</div>
								<div class="tab-pane fade" id="poker">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('CG','SL','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-creativegaming.png" alt="creativegaming"></a>
										<a onclick="popUpGameLink('FG','PK','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-fungaming.png" alt="fungaming"></a>
										<a onclick="popUpGameLink('JJ','CB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-jili.png" alt="jili"></a>
										<a onclick="popUpGameLink('KY','CB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-kaiyuan.png" alt="kaiyuan"></a>
										<a onclick="popUpGameLink('LG','CB','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-legaming.png" alt="legaming"></a>
										<a onclick="popUpGameLink('R8','SL','1','0',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/poker-rich88.png" alt="rich88"></a>
									</div>
								</div> -->
								<div class="tab-pane fade" id="lotto2">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('CE','LK','1','0',1000,600)"><img src="/build/assets/img/icons/2D/2D.png" alt="93connect"></a>
                    <a onclick="popUpGameLink('CE','LK','1','0',1000,600)"><img src="/build/assets/img/icons/2D/2DLive.png" alt="93connect"></a>
									</div>
								</div>
                <div class="tab-pane fade" id="lotto3">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('CE','LK','1','0',1000,600)"><img src="/build/assets/img/icons/3D/3D.png" alt="93connect"></a>
                    <a onclick="popUpGameLink('CE','LK','1','0',1000,600)"><img src="/build/assets/img/icons/3D/3DLive.png" alt="93connect"></a>
									</div>
								</div>
                <div class="tab-pane fade" id="football">
									<div class="gwrap_1">
										<a @click.prevent="redirectRoute('body-fb')"><img src="/build/assets/img/icons/football/Body.png" alt="93connect"></a>
                    <a @click.prevent="redirectRoute('maung-fb')"><img src="/build/assets/img/icons/football/Maung.png" alt="93connect"></a>
                    <a @click.prevent="redirectRoute('football-live')"><img src="/build/assets/img/icons/football/FootballLive.png" alt="93connect"></a>
									</div>
								</div>
                <div class="tab-pane fade" id="fishing">
									<div class="gwrap_1">
										<a @click.prevent="redirectRoute('fishing', {provider: 'Jili'})"><img src="/build/assets/img/icons/fishing/JILI.png" alt="93connect"></a>
                    <a @click.prevent="redirectRoute('fishing', {provider: 'JDB'})"><img src="/build/assets/img/icons/fishing/JDB.png" alt="93connect"></a>
									</div>
								</div>
								<!-- <div class="tab-pane fade" id="cock">
									<div class="gwrap_1">
										<a onclick="popUpGameLink('S6','OT','1','S6_AWS',1000,600)"><img src="https://aubet77mmk.com/mobile/images/index/cock-sv388.png" alt="cock ft sv388"></a>
									</div>
								</div> -->
							</div>
						</div>
					<!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { NoticeBar, Dialog, Toast } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { NoticeBar },
  data() {
    return {
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
      isLogged: false,
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    async redirectRoute(path, query = null) {
      try {
        console.log('query', query)
        if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },

    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path, query = null) {
      try {
        if (path === 'tip') {
          const text = this.$store.state.language === 'en'
            ? 'This feature is not support yet.'
            : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
                    this.$store.state.language === 'en'
                      ? 'Okay'
                      : 'Okay',
          }).then(() => {
          // on close
          });
        } else if(query) {
          this.$router.push({ path, query });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (authUser) {
      this.isLogged = true;
    }
    this.fetchWebsiteData();
    // this.fetchUser();
    // this.fetchTwoDdata();
    // this.fetchThreedDdata();
    // this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.detail-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #714DC7;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  color: #714DC7;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background-color: #714DC7;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.nav-pills.game-nav {
    padding-right: 5px;
  }
  .nav-pills .nav-link {
    border-radius: 10px !important;
    margin-bottom: 5px;
    /* padding: 10px; */
    font-size: 10px !important;
    text-align: center;
    color: #ffffff;
    /* background: linear-gradient(#aa9ace, #714DC7); */
  }
  .nav-pills:not(.nav-pills-circle) .nav-item {
        padding-right: 0;
        margin-bottom: 0;
        width: 100px;
    }

  .nav-pills .nav-link img {
    width: 18px !important;
    /* margin-right: 10px; */
    padding-bottom: 4px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff !important;
        background: linear-gradient(#f0dcf7, #8a69d9);
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.index_rightbar {
    padding: 0px 10px;
    overflow: auto;
    height: 150vw;
}
.tab-content.game a img {
    /* box-shadow: 0px 3px 14px -5px rgba(0, 0, 0, 0.4); */
    border-radius: 21px;
    margin-bottom: 10px;
    width: 100%;
}
.tab-content.game {
    padding-left: 3px;
}
.tab-content>.tab-pane {
    display: none;
    padding-right: 3px;
}
.tab-content>.active {
    display: block;
}


.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    font-family: NotoSansMyanmar;
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    font-family: NotoSansMyanmar;
    font-weight: 700 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  margin-top: -40px;
  z-index: 1;
  font-size: 20px;
  color: #172b4c;
  background: linear-gradient(#8635A5, #8635A5);
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

@media (max-width: 768px) {
  /* .middle-container {
    padding-top: 0.1rem !important;
  } */
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 12px;
    color: #ffffff;
    background: linear-gradient(#8635A5, #8635A5);
    border-radius: 7px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 10px !important;
    margin-left: 10px; 
    margin-right: 10px;
    color: #000000;
    /* background: linear-gradient(#aa9ace, #714DC7); */
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
}
</style>
