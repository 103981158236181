<template>
  <div class="container-fluid p-2">
        <div>
          <div class="my-3 d-flex flex-wrap px-0 mx-0">
            <button class="detail-button w-auto text-nowrap mx-auto my-1">Promotions</button>
            <!-- <button class="detail-button w-auto text-nowrap my-1">NEW MEMBER</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button> -->
          </div>
          <div class="col-12 px-1 mb-5 pb-5">
            <div class="card">
              <img src="/build/assets/img/icons/promotion-banner/member1.gif" style="border-top-right-radius: 5px; border-top-left-radius: 5px;" alt="">
              <div class="px-3 py-3 d-flex justify-content-between">
                <h2 class="my-auto" style="text-overflow: ellipsis; overflow: hidden; width: 100%; white-space: nowrap;">100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus</h2>
                <button class="detail-button col-3" @click="isLoading = !isLoading">Detail</button>
              </div>
            </div>
            <div class="card">
              <img src="/build/assets/img/icons/promotion-banner/member2.gif" style="border-top-right-radius: 5px; border-top-left-radius: 5px;" alt="">
              <div class="px-3 py-3 d-flex justify-content-between">
                <h2 class="my-auto" style="text-overflow: ellipsis; overflow: hidden; width: 100%; white-space: nowrap;">100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus</h2>
                <button class="detail-button col-3" @click="isLoading = !isLoading">Detail</button>
              </div>
            </div>
            <div class="card">
              <img src="/build/assets/img/icons/promotion-banner/member3.gif" style="border-top-right-radius: 5px; border-top-left-radius: 5px;" alt="">
              <div class="px-3 py-3 d-flex justify-content-between">
                <h2 class="my-auto" style="text-overflow: ellipsis; overflow: hidden; width: 100%; white-space: nowrap;">100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus100% Welcome Bonus</h2>
                <button class="detail-button col-3" @click="isLoading = !isLoading">Detail</button>
              </div>
            </div>
          </div>
        </div>

        <van-overlay :show="isLoading" @click="isLoading = false">
            <div class="wrapper col-12 d-flex h-100vh">
              <div class="col-11 card mx-auto my-auto py-4">
                <h2>100% Welcome Bonus</h2>
                 <span class="text-dark">Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni voluptatibus libero odit aliquam assumenda alias facilis iure necessitatibus officia earum delectus quis iste eligendi molestias qui aspernatur illum, voluptatem asperiores?</span>
              </div>
            </div>
        </van-overlay>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      websiteInfo: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    // const authUser = await this.fetchUser();
    // if (!authUser) {
    //   this.$router.push('/auth/login');
    // }
    this.fetchWebsiteData();
  },
};
</script>
<style scoped>
.card {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px !important; 
}
.detail-button {
  appearance: none;
  background-color: #8635A5;
  border: 2px solid #8635A5;
  text-transform: uppercase;
  border-radius: 14px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

/* .detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: #fff;
  background-color: #8635A5;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
} */
</style>